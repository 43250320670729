import {
  useUrlServerPagination,
  getRawCrawlId,
  getRawProjectId,
  useSession,
} from "@lumar/shared";
import React from "react";
import { useParams } from "react-router-dom";

import { GridSortItem } from "@mui/x-data-grid-pro";
import {
  OrderDirection,
  ProjectEdge,
  ProjectOrderField,
} from "../../../graphql";
import { useSearchParam } from "../../../shared/routing/useSearchParam";
import { useTimeZone } from "../../../shared/timezone/useTimeZone";
import {
  formatFilters,
  FormatFiltersEntry,
} from "../projects-filter/formatFilters";
import { isCrawlInProgress } from "../projects-filter/helpers";
import { AccountProjectsData, Project } from "./types";
import { useAccountProjectsQuery } from "./useAccountProjectsQuery";

const ACCOUNT_PROJECTS_PAGE_SIZE = 11;

export function useAccountProjectsData(
  baseFilter: FormatFiltersEntry[],
): AccountProjectsData {
  const { accountId } = useParams<{ accountId: string }>();

  const { account } = useSession();
  const { timeZoneOffset } = useTimeZone();

  const { pageInfo, ...pagination } = useUrlServerPagination(
    ACCOUNT_PROJECTS_PAGE_SIZE,
    ProjectOrderField.AllPagesTotal,
    OrderDirection.Desc,
  );

  const URLFilters = useSearchParam("filter");
  const parsedFilters = URLFilters && JSON.parse(unescape(atob(URLFilters)));
  const filter = parsedFilters
    ? formatFilters({
        filters: parsedFilters.filters,
        metadata: {
          currentBillingPeriod: account.subscription.currentBillingPeriod,
        },
        timeZoneOffset,
      })._and
    : [];

  const { data, loading, error, startPolling, stopPolling } =
    useAccountProjectsQuery({
      accountId,
      filter: { _and: [...baseFilter, ...filter] },
      baseFilter: { _and: baseFilter },
      pagination: pageInfo,
    });

  const hasCrawlInProgress = Boolean(
    data?.getAccount?.projects?.edges.find((p) =>
      isCrawlInProgress(p.node.lastCrawlStatus),
    ),
  );

  React.useEffect(() => {
    if (hasCrawlInProgress) {
      startPolling(60000);
    }
    return () => stopPolling();
  }, [hasCrawlInProgress, startPolling, stopPolling]);

  const projectData = React.useMemo<
    Pick<
      AccountProjectsData,
      | "projects"
      | "projectCount"
      | "projectTotalCount"
      | "projectLimit"
      | "crawlTypesMetadata"
    >
  >(() => {
    if (loading || !data) {
      return {
        projects: [],
        projectCount: 0,
        projectTotalCount: 0,
        crawlTypesMetadata: [],
      };
    }

    return {
      projects: data.getAccount?.projects.edges.map(formatProject) || [],
      projectCount: data.getAccount?.projects.totalCount ?? 0,
      projectTotalCount: data.getAccount?.allProjects?.totalCount ?? 0,
      projectLimit:
        data.getAccount?.accountSettings?.find((x) => x.code === "projects")
          ?.limit ?? undefined,
      crawlTypesMetadata: data.getCrawlTypesMetadata,
    };
  }, [data, loading]);

  return {
    ...projectData,
    loading,
    error,
    pagination: {
      ...pagination,
      paginationState: {
        ...pagination.paginationState,
        sortModel:
          pagination.paginationState.sortModel ??
          pageInfo.orderBy?.map(getSortModel),
      },
      pageSize: ACCOUNT_PROJECTS_PAGE_SIZE,
      paginationMode: "server",
    },
  };
}

function formatProject(edge: ProjectEdge): Project {
  const lastCrawl = edge.node.crawls?.edges?.[0]?.node;
  return {
    id: getRawProjectId(edge.node.id),
    name: edge.node.name,
    primaryDomain: edge.node.primaryDomain,
    crawlTypes: edge.node.crawlTypes,
    createdAt: edge.node.createdAt ? new Date(edge.node.createdAt) : undefined,
    lastCrawlId: lastCrawl ? getRawCrawlId(lastCrawl.id) : undefined,
    lastCrawlStatus: edge.node.lastCrawlStatus ?? undefined,
    lastCrawlFinishedAt: lastCrawl?.finishedAt
      ? new Date(lastCrawl.finishedAt)
      : undefined,
    lastCrawlCreatedAt: lastCrawl?.createdAt
      ? new Date(lastCrawl.createdAt)
      : undefined,

    lastFinishedCrawlId: edge.node.lastFinishedCrawl
      ? getRawCrawlId(edge.node.lastFinishedCrawl.id)
      : undefined,
    finishedAt: edge.node.finishedAt
      ? new Date(edge.node.finishedAt)
      : undefined,

    allPagesTotal: edge.node.allPagesTotal ?? undefined,
    crawlsTotalCount: edge.node.crawlsTotalCount ?? 0,
    schedule: edge.node.schedule
      ? {
          id: edge.node.schedule.id,
          scheduleFrequency: edge.node.schedule.scheduleFrequency,
          nextRunTime: new Date(edge.node.schedule.nextRunTime),
        }
      : undefined,
  };
}

function getSortModel(order: {
  field: string;
  direction: OrderDirection;
}): GridSortItem {
  return {
    field: order.field,
    sort: order.direction === OrderDirection.Asc ? "asc" : "desc",
  };
}
