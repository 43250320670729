import { useEffect } from "react";
import { useTranslation, Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { AppSkeleton } from "../../app-skeleton/AppSkeleton";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(35),
    color: theme.palette.grey[900],
    fontWeight: 600,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "block",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
  },
  skeleton: {
    width: 200,
    display: "block",
    height: 35,
  },
  backButton: {
    padding: theme.spacing(0.25, 1, 0.25, 1),
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  buttonLabel: {
    color: theme.palette.grey[800],
    marginTop: 0,
    marginBottom: 0,
  },
  icon: {
    "& > *:first-child": {
      fontSize: theme.typography.pxToRem(23),
    },
  },
}));

export interface PageTitleProps {
  title?: string;
  loading?: boolean;
  projectName?: string;
}

export function PageTitle({
  title = "Untitled Page",
  loading = false,
  projectName,
}: PageTitleProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("navigation");
  const defaultTitle = t("title");

  useEffect(() => {
    const titles = [
      title,
      projectName && !title.includes(projectName) ? projectName : undefined,
      defaultTitle,
    ];
    // eslint-disable-next-line fp/no-mutation
    document.title = titles.filter((x) => x?.length).join(" | ");
    return () => {
      // eslint-disable-next-line fp/no-mutation
      document.title = defaultTitle;
    };
  }, [title, projectName, defaultTitle]);

  if (loading) {
    return <AppSkeleton animation="wave" className={classes.skeleton} />;
  }

  return (
    <Typography className={classes.title} component="h1">
      {title}
    </Typography>
  );
}

export function useDefaultAppTitle(): void {
  const { t } = useTranslation("navigation");
  const defaultTitle = t("title");

  useEffect(() => {
    // eslint-disable-next-line fp/no-mutation
    document.title = defaultTitle;
  }, [defaultTitle]);
}
